var React = require('react');
const { useState } = require('react');
var ContextMenu = require('ContextMenu');
require('./Sensor.scss');
var { useTranslation, withTranslation } = require('react-i18next');
const { connect } = require('react-redux');
const ExtraSensorContent = require('ExtraSensorContent');

const Sensor = React.memo(
  ({
    type,
    sub_type,
    valves,
    transmitter_id,
    coordinates,
    m3_per_pulse,
    manufacturer,
    ignore_in_calculations,
    paused,
    should_consider_as_wfr,
    onEdit,
    onLocate,
    onIgnore,
    onPaused,
    onDelete,
    onConsiderAsWFR,
    name,
    userData,
    offset,
    fert_meters,
    fertilizer_data,
    installation_depth,
  }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);

    const typeOptions = {
      motorola: 'Motorola',
      ndrip: 'N-Drip Flow Meter',
      galcon: 'Galcon',
      aquastride: 'Aquastride',
      'tal-gil': 'Tal Gil',
      soil_temp: 'Soil Temp Sensor',
    };

    const menuOptions =
      type === 'piezometer'
        ? [
            {
              title: t('edit'),
              icon: require('images/icons/edit_field/edit_icon.svg'),
              func: onEdit,
            },
            {
              title: t('locate_coordinates'),
              icon: require('images/icons/edit_field/navigate_icon.svg'),
              func: onLocate,
            },
            {
              type: 'divider',
            },
            {
              title:
                type == 'wp'
                  ? t('ignore_sensor_during_calculations')
                  : t('ignore_sensor_during_calculations_and_hide'),
              type: 'checkBox',
              func: onIgnore,
              checkBoxOn: ignore_in_calculations,
            },
            {
              title: t('stop_data_collection'),
              type: 'checkBox',
              func: onPaused,
              checkBoxOn: paused,
            },
            {
              title: t('consider_as_flow_meter'),
              type: 'checkBox',
              func: onConsiderAsWFR,
              checkBoxOn: should_consider_as_wfr,
            },
            {
              type: 'divider',
            },
            {
              title: t('delete'),
              icon: require('images/icons/trash.svg'),
              func: onDelete,
            },
          ]
        : [
            {
              title: t('edit'),
              icon: require('images/icons/edit_field/edit_icon.svg'),
              func: onEdit,
            },
            {
              title: t('locate_coordinates'),
              icon: require('images/icons/edit_field/navigate_icon.svg'),
              func: onLocate,
            },
            {
              type: 'divider',
            },
            {
              title:
                type == 'wp'
                  ? t('ignore_sensor_during_calculations')
                  : t('ignore_sensor_during_calculations_and_hide'),
              type: 'checkBox',
              func: onIgnore,
              checkBoxOn: ignore_in_calculations,
            },
            {
              title: t('stop_data_collection'),
              type: 'checkBox',
              func: onPaused,
              checkBoxOn: paused,
            },
            {
              type: 'divider',
            },
            {
              title: t('delete'),
              icon: require('images/icons/trash.svg'),
              func: onDelete,
              textColor: '#F66B7A',
            },
          ];

    const middleColTitle = valves?.length
      ? t('valves')
      : type === 'pulse'
        ? t('water_meter_units')
        : type === 'piezometer'
          ? `${t('offset')} (${userData?.length})`
          : type === 'soil_temp'
            ? t('installation_depth')
            : '';
    const middleColContent = valves?.length ? (
      <div className='d-flex flex-wrap'>
        {valves.map((valve) => (
          <div
            key={valve}
            className='text-size-14 font-weight-semi-bold bg-grey96'
            style={{
              lineHeight: 1,
              padding: '2px 12px 2px',
              margin: '0 4px 4px 0',
              borderRadius: '500px',
              height: '22px',
            }}
          >
            {valve}
          </div>
        ))}
      </div>
    ) : middleColTitle === t('water_meter_units') ? (
      <div style={{ lineHeight: 1 }}>{m3_per_pulse}</div>
    ) : middleColTitle === `${t('offset')} (${userData?.length})` ? (
      <div style={{ lineHeight: 1 }}>{offset || 0}</div>
    ) : middleColTitle === t('installation_depth') ? (
      <div style={{ lineHeight: 1 }}>{installation_depth || 0}</div>
    ) : null;

    const extraTalGilContent =
      fert_meters && Object.keys(fert_meters).length
        ? Object.keys(fert_meters).map((fertMeterID, index) => {
            return fert_meters[fertMeterID].map((fertilizer, index) => {
              return (
                <ExtraSensorContent
                  key={index}
                  chosenFertType={
                    fertilizer_data[fertilizer.type].fertilizer_type
                  }
                  chosenFertID={fertMeterID}
                  chosenStartDate={fertilizer.start_date}
                />
              );
            });
          })
        : null;

    return (
      <div className='d-flex mb-4'>
        <div className='d-flex align-items-center justify-content-between flex-column card-body card pt-0 pb-0'>
          <div
            className='d-flex justify-content-between align-items-start flex-grow-1 flex-row'
            style={{ padding: '8px 0px', gap: '5px' }}
          >
            <div className='text-grey45' style={{ width: '120px', gap: '4px' }}>
              <label
                style={{ lineHeight: 1 }}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'
              >
                {t('type')}
              </label>
              <div style={{ lineHeight: 1 }}>
                {type === 'wp'
                  ? 'N-Drip Tensiometer'
                  : type === 'pulse'
                    ? 'N-Drip Pulse'
                    : type === 'piezometer'
                      ? 'N-Drip Piezometer'
                      : type === 'soil_temp'
                        ? 'Soil Temp Sensor'
                        : typeOptions[manufacturer] + (sub_type || '')}
              </div>
            </div>
            <div className='text-grey45' style={{ width: '120px', gap: '4px' }}>
              <label
                style={{ lineHeight: 1 }}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'
              >
                {t('transmitter_id')}
              </label>
              <div style={{ lineHeight: 1 }}>{transmitter_id}</div>
            </div>
            <div
              className='text-grey45'
              style={{ width: '124px', gap: '4px !important' }}
            >
              <label
                style={{ lineHeight: 1 }}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'
              >
                {t('name')}
              </label>
              <div style={{ lineHeight: 1 }}>{name}</div>
            </div>
            <div className='text-grey45' style={{ width: '184px', gap: '4px' }}>
              <label
                style={{ lineHeight: 1 }}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'
              >
                {middleColTitle}
              </label>
              <div style={{ lineHeight: 1 }}>{middleColContent}</div>
            </div>
            <div
              className='text-grey45 d-none d-sm-block'
              style={{ width: '160px', gap: '4px' }}
            >
              <label
                style={{ lineHeight: '140%' }}
                className='font-weight-semi-bold mb-0 text-size-14 lh-1'
              >
                {t('location')}
              </label>
              <div
                style={{
                  lineHeight: '120%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <span className='text-size-14 font-weight-semi-bold'>
                  Lat. &nbsp;&nbsp;&nbsp;
                </span>
                {coordinates.latitude || ''}
              </div>
              <div
                style={{
                  lineHeight: '120%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <span className='text-size-14 font-weight-semi-bold'>
                  Long.{' '}
                </span>
                {coordinates.longitude || ''}
              </div>
            </div>
          </div>
          {extraTalGilContent}
        </div>

        <div className='d-flex align-items-center justify-content-between position-relative'>
          {showMenu && (
            <ContextMenu options={menuOptions} onToggle={setShowMenu} />
          )}
          <button
            onClick={(e) => {
              setShowMenu(!showMenu);
              e.stopPropagation();
            }}
            type='button'
            className={`btn d-flex justify-content-center align-items-center sensorMenuBtn ${showMenu ? 'active' : ''}`}
          >
            <img src={require('images/icons/three-dots-horizontal.svg')} />
          </button>
        </div>
      </div>
    );
  },
);

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
    fertilizer_data: state.fertilizer_data.fertilizer_data,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(Sensor));
