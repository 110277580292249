const React = require('react');
const { connect } = require('react-redux');
const phasesManager = require('phasesManager');
const { useState, useEffect } = require('react');
const PhasesViewContainer = require('PhasesViewContainer');
const { latestObjectfromDict } = require('helpers');
const phasesCSS = require('phasesCSS');

const PhasesContainer = (props) => {
  const [phenologicalPhasesData, setPhenologicalPhasesData] = useState(null);

  useEffect(() => {
    if (
      props.selectedField &&
      props.selectedField.crop_data &&
      props.selectedField.crop_data.kc_table &&
      props.selectedField.crop_data.kc_table.type === 'byGrowthPhase'
    ) {
      let data = phasesManager.transformKCTableDataToDisplayData(
        props.selectedField.crop_data.kc_table,
        props.selectedField.crop_data?.sow_date,
      );
      setPhenologicalPhasesData(data);
    }
  }, [props?.selectedField?.crop_data]);

  useEffect(() => {
    if (
      props?.selectedField?.historical_data?.accu_gdd &&
      props?.selectedField?.historical_data?.accu_gdd?.values &&
      props.selectedField.crop_data.kc_table.type === 'byGrowthPhaseGDD'
    ) {
      let currentGDD = latestObjectfromDict(
        props?.selectedField?.historical_data?.accu_gdd?.values,
      );
      let data = phasesManager.transformKCTableDataToDisplayData(
        props.selectedField.crop_data.kc_table,
        props.selectedField.crop_data?.sow_date,
        currentGDD,
      );
      setPhenologicalPhasesData(data);
    }
  }, [props?.selectedField?.historical_data?.accu_gdd]);

  return (
    <div>
      {phenologicalPhasesData && (
        <PhasesViewContainer phenologicalPhasesData={phenologicalPhasesData} />
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    selectedField: state.distribution_data.selected_entities.field,
    loadHistoryStatus: state.distribution_data.field_load_history,
  };
};

const mapDispatchToProps = {};

module.exports = connect(mapStateToProps, mapDispatchToProps)(PhasesContainer);
