var React = require('react');
var { connect } = require('react-redux');
var { withTranslation, useTranslation } = require('react-i18next');

const PhasesViewTitle = (props) => {
  const { t } = useTranslation();

  const EditButtonComponent = ({ OnButtonClicked }) => {
    return (
      <div style={{ lineHeight: 'normal', width: '100%' }}>
        <div className='text-right'>
          <button
            onClick={OnButtonClicked}
            className='btn addBtn font-weight-bold text-grey45 pt-1 pb-1 pl-2 pr-3 text-size-14'
            style={{
              borderRadius: '3px',
              border: '1px solid #919DB4',
              width: '100%',
            }}
          >
            <img
              src={require('images/icons/edit_pen_icon.svg')}
              className='img-fluid'
              alt='move to edit mode'
              style={{ marginBottom: '2px' }}
            />
            <span style={{ marginLeft: '4px' }}>{t('edit')}</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className='d-flex'
      style={{
        width: '278px',
        flexDirection: 'column',
        paddingRight: '24px',
        borderRight: '1px solid #DCE0E8',
        justifyContent: 'center',
      }}
    >
      {/*{props.shouldDisplayEditMode ? <EditButtonComponent OnButtonClicked={props.OnEditModePressed}/> :*/}
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            gap: '4px',
            lineHeight: '16px',
          }}
        >
          <div className={'text-grey45 text-style-titles-14-regular'}>
            {t('KC_phases_title')}
          </div>
          <div
            className={
              'text-blue-secondary text-grey45 text-style-titles-14-bold'
            }
          >
            {props.currentPhase}
          </div>
        </div>
        {props.endOfSeason && (
          <div
            style={{ paddingTop: '4px' }}
            className={'text-grey71 text-style-titles-12-regular'}
          >
            {props.endOfSeason}
          </div>
        )}
      </div>
      {/*}*/}
    </div>
  );
};

module.exports = connect()(withTranslation()(PhasesViewTitle));
