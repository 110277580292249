var React = require('react');
var moment = require('moment');
var sensorsManager = require('sensorsManager');
var { fertilizerNameToDisplay } = require('nitrogenManager');
var { upper, parseShortFloat } = require('@managers/helpers');
var {
  nitroUnitsToFertUnits,
  fertUnitsToNitroUnits,
} = require('@managers/unitsManager');

export function EventPopUp(
  startTime,
  endTime,
  eventType,
  unit,
  value,
  sourceObj,
  shouldDisplayTrashIcon,
  codeSource,
  timezone,
  t,
  nitrogenInFertUnits = '',
) {
  let begDate = new Date(startTime);
  let endDate = new Date(endTime);
  let fullBegDateStr, fullBegTimeStr, fullEndDateStr, fullEndTimeStr;
  if (codeSource === 'planner') {
    fullBegDateStr = moment(begDate).format('MMM D').toUpperCase();
    fullBegTimeStr = moment(begDate).format('hh:mm a');
    fullEndDateStr = moment(endDate).format('MMM D').toUpperCase();
    fullEndTimeStr = moment(endDate).format('hh:mm a');
  } else {
    fullBegDateStr = moment(begDate).tz(timezone).format('MMM D').toUpperCase();
    fullBegTimeStr = moment(begDate).tz(timezone).format('hh:mm a');
    fullEndDateStr = moment(endDate).tz(timezone).format('MMM D').toUpperCase();
    fullEndTimeStr = moment(endDate).tz(timezone).format('hh:mm a');
  }
  let imgSrc;
  let deleteRow = '';
  let source = '';
  let firstRow = '';
  let extraRow = '';
  let hideEndDate = false;
  value = parseFloat(value).toFixed(3) * 1;

  if (eventType === 'irrigation') {
    imgSrc = require('images/icons/graph/irrigation.svg');
    if (sourceObj.hasOwnProperty('source')) {
      if (sourceObj.hasOwnProperty('manufacturer')) {
        source = sensorsManager.sensorsMap.get(
          sourceObj.source + '_' + sourceObj.manufacturer,
        );
      } else {
        source = sensorsManager.sensorsMap.get(sourceObj.source);
      }
    } else {
      source = sourceObj.hasOwnProperty('type') ? 'Automatic' : 'Manual';
    }
    let disableEventRecovery = ['piezometer', 'wfr_ndrip'].includes(
      sourceObj.source,
    );
    let sensorId = sourceObj.sensor_id;
    deleteRow =
      "<div style='display: flex;align-self: end;padding-right: 8px;padding-left: 8px;justify-self: self-end' class=" +
      (shouldDisplayTrashIcon ? '' : 'd-none') +
      "><a href='#'><img id='deleteIrrigationButton' date='" +
      startTime +
      "' disableEventRecovery='" +
      disableEventRecovery +
      "' sensorId='" +
      sensorId +
      "' src='" +
      require('images/icons/graph/trash_graph.svg') +
      "'/></a></div>";
  } else if (eventType === 'fertilization') {
    imgSrc = require('images/icons/planner/fert_icon_grey.svg');
    if (sourceObj.fertType && sourceObj.fertAmount) {
      nitrogenInFertUnits = fertUnitsToNitroUnits(nitrogenInFertUnits);
      if (!['no3', 'nh4'].includes(sourceObj.fertType))
        extraRow =
          "<div class='pl-2 mr-1 text-grey71 font-weight-semi-bold' style='font-size: 14px;white-space: nowrap'>" +
          upper(t('containing')) +
          ' ' +
          value +
          ' ' +
          nitrogenInFertUnits +
          '</div>';
      value =
        fertilizerNameToDisplay(sourceObj.fertType) +
        ': ' +
        parseShortFloat(sourceObj.fertAmount);
      unit = nitroUnitsToFertUnits(unit);
    }
    deleteRow =
      "<div style='align-self-center; display: flex;align-self: end;padding-right: 8px;padding-left: 8px;justify-self: self-end' class=" +
      (shouldDisplayTrashIcon ? '' : 'd-none') +
      "><a href='#'><img  id='deleteFertilizationButton' date='" +
      startTime +
      "' src='" +
      require('images/icons/graph/trash_graph.svg') +
      "'/></a></div>";
    source = sourceObj.source;
    if (codeSource === 'sensorGraph' || !endTime) {
      hideEndDate = true;
    }
  } else {
    // rain event
    imgSrc = require('images/icons/graph/rain_icon.svg');
    if (codeSource === 'sensorGraph') {
      let source = sourceObj.source;
      deleteRow =
        "<div style='display: flex;align-self: end;padding-right: 8px;padding-left: 8px;justify-self: self-end' class=" +
        (shouldDisplayTrashIcon ? '' : 'd-none') +
        "><a href='#'><img id='deletePrecipitationButton' date='" +
        startTime +
        "' source='" +
        source +
        "' src='" +
        require('images/icons/graph/trash_graph.svg') +
        "'/></a></div>";
    }
  }
  let popupHeight = !!extraRow ? '112px' : '96px';

  if (codeSource === 'planner') {
    firstRow =
      "<div style='height: " +
      popupHeight +
      "; pointer-events: none; flex-direction: column;position: absolute;box-shadow: 0px 4px 10px rgba(145, 157, 180, 0.19); border-radius: 8px' class='pr-2 pl-2 dropdown dropdown-hover dropdown-menu bg-white d-flex mt-1'>";
  }
  source = source || '';
  let modal =
    firstRow +
    "<div style='flex-direction: row ;display: flex;width: 100%;margin-bottom: 8px'>" +
    "<div class='align-self-center font-weight-normal'><img style='width: 21px;height: 21px' src='" +
    imgSrc +
    "'/></div>" +
    '<div>' +
    "<div class='pl-2 mr-1 text-grey71 font-weight-semi-bold align-self-center' style='font-size: 14px;white-space: nowrap'>" +
    value +
    ' ' +
    unit +
    '</div>' +
    extraRow +
    '</div>' +
    "<div><div class='align-self-top font-weight-semi-bold " +
    !!source +
    "' style='font-size: 14px;padding:0px 6px; background-color: #EEF1F6;color: #4D5674;border-radius: 3px; white-space: nowrap; margin-left: auto'>" +
    upper(source) +
    '</div></div>' +
    '</div>' +
    "<div style='width:100%;border-top: 1px solid #DCE0E8;align-self: center;'></div>" +
    "<div style='flex-direction: row ;display: flex; align-items:center; width: 100%;margin-top: 8px'>" +
    "<div class='font-weight-normal align-self-center'><img style='width: 21px;height: 21px' src='" +
    require('images/icons/graph/timer.svg') +
    "'/></div>" +
    "<div style='flex-direction: column'>" +
    "<div class='pl-2 text-grey71 align-self-center' style='font-size: 12px;white-space: nowrap'>" +
    fullBegDateStr +
    '</div>' +
    "<div class='pl-2 text-grey71 font-weight-semi-bold align-self-center' style='font-size: 14px;white-space: nowrap'>" +
    fullBegTimeStr +
    '</div>' +
    '</div>' +
    "<div class='pl-2 pr-2 text-grey71 align-self-center " +
    (hideEndDate ? 'd-none' : '') +
    "' style='font-size: 12px;white-space: nowrap'>-</div>" +
    '<div class=' +
    (hideEndDate ? 'd-none' : '') +
    " style='flex-direction: column;'>" +
    "<div class='pr-2 text-grey71 align-self-center' style='font-size: 12px;white-space: nowrap'>" +
    fullEndDateStr +
    '</div>' +
    "<div class='pr-2 text-grey71 font-weight-semi-bold align-self-center' style='font-size: 14px;white-space: nowrap;padding-right: 8px'>" +
    fullEndTimeStr +
    '</div>' +
    '</div>' +
    "<div style='width: 100%'></div>" +
    deleteRow +
    '</div>' +
    '</div>' +
    '</div>';

  return modal;
}
