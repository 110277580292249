import {
  NDripPiezometer,
  PulseSensor,
  SoilTempSensor,
  WFRSensor,
} from "../model/GeneralClasses";
import { convert } from "./unitsManager";
import * as sensorsManager from "./sensorsManager";
import * as weatherManager from "./weatherManager";
import { getRelevantCyclicDateByDate, error_message, upper } from "./helpers";

var axios = require("axios");
var GeneralClasses = require("@model/GeneralClasses");
var moment = require("moment-timezone");
var React = require("react");
const geoarea = require("geo-area")(/*options*/ { x: "lng", y: "lat" });

const SCIROOT_FIELD_PATH = "field";
const SCIROOT_FIELD_RECORDS_PATH = "field_records";

export const STRESSED_LIMIT = 0.4;

export const stress_levels = {
  critical: {
    min_value: 0.0,
    max_value: 0.15,
    color: "rgb(246, 107, 122)",
    background: "#FFF5F6",
    message: "stress_level_very_high",
    commandMessage: "irrigate_command",
    group: 1,
  },
  stressed: {
    min_value: 0.15,
    max_value: STRESSED_LIMIT,
    color: "rgb(255, 179, 2)",
    background: "#FFFBF2",
    message: "stress_level_high",
    commandMessage: "irrigate_command",
    group: 2,
  },
  getting_closer: {
    min_value: STRESSED_LIMIT,
    max_value: 0.5,
    color: "rgb(10, 203, 188)",
    background: "#E4F8F6",
    message: "stress_level_getting_low",
    commandMessage: "get_ready_to_irrigate",
    group: 3,
  },
  valid: {
    min_value: 0.5,
    max_value: 1.01,
    color: "rgb(10, 203, 188)",
    background: "#E4F8F6",
    message: "stress_level_no_stress",
    commandMessage: "dont_irrigate_command",
    group: 4,
  },
};

export class FieldDataItem {
  constructor(value, date) {
    this.value = value;
    this.date = date;
  }
}

export class FieldDataItems {
  constructor(irrigationDataItem, stressDataItem, accumulatedETc) {
    this.irrigationDataItem = irrigationDataItem;
    this.stressDataItem = stressDataItem;
    this.accumulatedETc = accumulatedETc;
  }
}

export var isRecommendationOutOfDate = (date) => {
  var day_diff = moment().diff(date, "hours");

  if (day_diff > 24) {
    return true;
  } else {
    return false;
  }
};

export var last_recommendation_date = (field) => {
  var wp_date_last = "1494112409";
  if (field.historical_data.ir_date) {
    var wp_date_keys = Object.keys(field.historical_data.ir_date.values);
    wp_date_keys.sort();
    wp_date_keys.reverse();

    wp_date_last = field.historical_data.ir_date.values[wp_date_keys[0]];
  }

  return wp_date_last;
};

export var last_recommendation = (field) => {
  let recommendationDataItem = new FieldDataItem(-1.0, "N/A");
  if (
    field &&
    field.historical_data &&
    field.historical_data.ir &&
    Object.keys(field.historical_data.ir.values).length > 0
  ) {
    var keys = Object.keys(field.historical_data.ir.values);
    keys.sort();
    keys.reverse();

    var last = field.historical_data.ir.values[keys[0]];
    var wp_date_last = last_recommendation_date(field);

    if (!isRecommendationOutOfDate(moment.unix(wp_date_last))) {
      recommendationDataItem.value = parseFloat(last);
      recommendationDataItem.date = wp_date_last;
    }
  }

  return recommendationDataItem;
};

export var last_accu_etc = (field) => {
  let recommendationDataItem = new FieldDataItem(-1.0, "N/A");
  if (
    field &&
    field.historical_data &&
    field.historical_data.accu_etc &&
    Object.keys(field.historical_data.accu_etc.values).length > 0
  ) {
    var keys = Object.keys(field.historical_data.accu_etc.values);
    keys.sort();
    keys.reverse();

    var last = field.historical_data.accu_etc.values[keys[0]];
    var wp_date_last = last_recommendation_date(field);

    if (!isRecommendationOutOfDate(moment.unix(wp_date_last))) {
      recommendationDataItem.value = parseFloat(last);
      recommendationDataItem.date = wp_date_last;
    }
  }

  return recommendationDataItem;
};

export var getSelectedFieldArea = (field, userAreaUnits) => {
  if (field.geo_data.area_size === undefined) {
    return getFieldArea(field.geo_data.areas);
  } else {
    return convert(
      parseFloat(field.geo_data.area_size),
      userAreaUnits,
      "dunam",
      "area",
    );
  }
};

export var getFieldArea = (fieldAreas) => {
  let areas = [];
  let polygon = undefined;

  for (let currentArea in fieldAreas) {
    polygon = [];
    for (let currentCoordinate in fieldAreas[currentArea]) {
      let curr = fieldAreas[currentArea][currentCoordinate];
      polygon.push({
        lng: curr.longitude,
        lat: curr.latitude,
      });
    }
    // calculate and convert to dunam from squared meter
    let area = geoarea(polygon) / 1000.0;
    areas.push(area);
  }

  var sum = areas.reduce(function (a, b) {
    return a + b;
  }, 0);

  return sum.toFixed(2);
};

export var last_kc = (field) => {
  if (
    field &&
    field.historical_data &&
    field.historical_data.kc &&
    Object.keys(field.historical_data.kc.values).length > 0
  ) {
    var keys = Object.keys(field.historical_data.kc.values);
    keys.sort();
    keys.reverse();

    var today = moment()
      .tz(field.geo_data.time_zone)
      .startOf("day")
      .format("YYYY-MM-DD");
    var topKey = moment.unix(keys[0]).format("YYYY-MM-DD");
    var date_string = today == topKey ? "(today)" : "(" + topKey + ")";

    var last = field.historical_data.kc.values[keys[0]];

    return [last, date_string];
  } else {
    return ["N/A", "N/A"];
  }
};

export var last_etc = (field) => {
  if (
    field &&
    field.historical_data &&
    field.historical_data.etc &&
    Object.keys(field.historical_data.etc.values).length > 0
  ) {
    var keys = Object.keys(field.historical_data.etc.values);
    keys.sort();
    keys.reverse();

    var today = moment()
      .tz(field.geo_data.time_zone)
      .startOf("day")
      .format("YYYY-MM-DD");
    var topKey = moment.unix(keys[0]).format("YYYY-MM-DD");
    var date_string = today == topKey ? "(today)" : "(" + topKey + ")";

    var last = field.historical_data.etc.values[keys[0]];

    return [last, date_string];
  } else {
    return ["N/A", "N/A"];
  }
};

export var is_kc_out_of_date = (field) => {
  if (field.crop_data.kc_table.type == "byGrowthPhase") {
    var sow_date = moment(field.crop_data.sow_date);
    var today = moment();

    var days_between = today.diff(sow_date, "days");

    var total_duration = Object.keys(field.crop_data.kc_table.data).reduce(
      (total, phase) => {
        return (
          total + parseInt(field.crop_data.kc_table.data[phase]["duration"])
        );
      },
      0,
    );

    if (days_between > total_duration) {
      return true;
    }
  }

  return false;
};

export var getKCPhase = (field) => {
  if (field.crop_data.kc_table.type == "byGrowthPhase") {
    var sowDate = moment(field.crop_data.sow_date);
    var today = moment();
    var days_between = today.diff(sowDate, "days");

    var durationSum = 0;
    let index = 0;

    for (index in field.crop_data.kc_table.data["byGrowthPhase"]) {
      durationSum += parseInt(
        field.crop_data.kc_table.data["byGrowthPhase"][index].duration,
      );

      if (days_between < durationSum) {
        break;
      }
    }

    return field.crop_data.kc_table.data["byGrowthPhase"][index].name;
  } else {
    var currentMonth = moment().format("MMMM");
    return currentMonth;
  }
};

export var last_stress_level = (field) => {
  let stressDataItem = new FieldDataItem(-1.0, "N/A");

  if (
    field &&
    field.historical_data &&
    field.historical_data.adjusted_stress &&
    Object.keys(field.historical_data.adjusted_stress.values).length > 0
  ) {
    var keys = Object.keys(field.historical_data.adjusted_stress.values);
    keys.sort();
    keys.reverse();

    var wp_date_last = last_recommendation_date(field);

    if (!isRecommendationOutOfDate(moment.unix(wp_date_last))) {
      var last_stress_level = parseFloat(
        field.historical_data.adjusted_stress.values[keys[0]],
      );

      if (last_stress_level > 0) {
        last_stress_level =
          STRESSED_LIMIT + (1 - STRESSED_LIMIT) * last_stress_level;
      } else {
        last_stress_level = STRESSED_LIMIT * (1 + last_stress_level);
      }

      stressDataItem.value = parseFloat(last_stress_level);
      stressDataItem.date = wp_date_last;
    }
  }

  return stressDataItem;
};

// validating the stress level with the recommended amount of irrigation
// there's a situation where the stress is low but there is a need for irrigation, this happens when water potential is high for a while.
export var getFieldItems = (field) => {
  var lastIrrigationRecommendation = last_recommendation(field);
  let lastStressLevel = last_stress_level(field);
  let lastAccuETc = last_accu_etc(field);

  if (
    lastStressLevel.value > STRESSED_LIMIT &&
    lastIrrigationRecommendation.value > 0
  ) {
    lastStressLevel.value = 0.05;
  }

  return new FieldDataItems(
    lastIrrigationRecommendation,
    lastStressLevel,
    lastAccuETc,
  );
};

export var get_stress_color = (stress_level) => {
  for (var key in stress_levels) {
    var current_stress_record = stress_levels[key];

    if (
      stress_level >= current_stress_record["min_value"] &&
      stress_level < current_stress_record["max_value"]
    ) {
      return current_stress_record["color"];
    }
  }

  return "#696969";
};

export var get_stress_group = (stress_level) => {
  for (var key in stress_levels) {
    var current_stress_record = stress_levels[key];

    if (
      stress_level >= current_stress_record["min_value"] &&
      stress_level < current_stress_record["max_value"]
    ) {
      return current_stress_record["group"];
    }
  }

  return 5;
};

export var compareFields = (_f1, _f2) => {
  let f1StressGroup = get_stress_group(_f1.stressLevel);
  let f2StressGroup = get_stress_group(_f2.stressLevel);

  //primary sort - by stress group (stress level categorized)
  if (f1StressGroup === f2StressGroup) {
    //secondary sort - by accumulated ETC
    if (_f1.accumulatedETc === _f2.accumulatedETc) {
      //third sort - by water Potential samples (if mini graph contains or doesn't)
      if (_f1.wpSamples !== undefined && _f2.wpSamples !== undefined) {
        return 0;
      } else if (_f1.wpSamples !== undefined && _f2.wpSamples === undefined) {
        return -1;
      } else return 1;
    } else if (_f1.accumulatedETc > _f2.accumulatedETc) {
      return -1;
    } else return 1;
  } else if (f1StressGroup < f2StressGroup) {
    return -1;
  } else return 1;
};

export var get_stress_background_color = (stress_level) => {
  for (var key in stress_levels) {
    var current_stress_record = stress_levels[key];

    if (
      stress_level >= current_stress_record["min_value"] &&
      stress_level < current_stress_record["max_value"]
    ) {
      return current_stress_record["background"];
    }
  }

  return "#696969";
};

export var get_stress_message = (
  stress_level,
  irrigation_recommendation,
  accumulated_etc,
  recommendation_date,
  units,
) => {
  let messageObject = {
    mainMessage: "",
    commandMessage: "",
    irrigationAmountMessage: "",
  };

  if (irrigation_recommendation == -1) {
    messageObject["mainMessage"] = "no_recommendation_available";
    return messageObject;
  }

  for (var key in stress_levels) {
    var current_stress_record = stress_levels[key];

    if (
      stress_level >= current_stress_record["min_value"] &&
      stress_level < current_stress_record["max_value"]
    ) {
      if (key == "critical" || key == "stressed") {
        messageObject["irrigationAmountMessage"] =
          irrigation_recommendation + " " + units;
      } else if (accumulated_etc == 0) {
        messageObject["irrigationAmountMessage"] = "good";
      } else {
        messageObject["irrigationAmountMessage"] =
          accumulated_etc + " " + units;
      }

      messageObject["commandMessage"] = current_stress_record["commandMessage"];
      messageObject["mainMessage"] = current_stress_record["message"];

      return messageObject;
    }
  }

  return "";
};

var sensorObjectsToDictionary = (sensors) => {
  let dict = {};

  for (let currentSensorKey in sensors) {
    // Since transmitter id is presented with the port name within the app, we change it before we send it to the server.
    let givenTransmitterId = sensors[currentSensorKey]["transmitter_id"];

    let transmitterId = givenTransmitterId;
    let port = undefined;
    let transmitterIdParts = givenTransmitterId.match(/(.*\d)([^\d]+)?$/);
    if (transmitterIdParts) {
      transmitterId = transmitterIdParts[1];
      port = transmitterIdParts[2];
    }

    let coordinates = sensors[currentSensorKey]["coordinates"];
    let name = sensors[currentSensorKey]["name"];
    let sample_interval = sensors[currentSensorKey]["sample_interval"];
    let transmission_interval =
      sensors[currentSensorKey]["transmission_interval"];

    let type = sensors[currentSensorKey]["type"];
    let ignore_in_calculations =
      sensors[currentSensorKey]["ignore_in_calculations"];
    let paused = sensors[currentSensorKey]["paused"];

    let sensorDictionary = {
      coordinates: coordinates,
      name: name,
      type: type,
      transmitter_id: transmitterId,
      port: port,
      ignore_in_calculations: ignore_in_calculations,
      paused: paused,
      transmission_interval: transmission_interval,
      sample_interval: sample_interval,
    };

    if (sensors[currentSensorKey] instanceof GeneralClasses.WFRSensor) {
      sensorDictionary["m3_per_pulse"] =
        sensors[currentSensorKey]["m3_per_pulse"];
      if (
        sensors[currentSensorKey] instanceof GeneralClasses.WFRSensorTalgil ||
        sensors[currentSensorKey] instanceof GeneralClasses.WFRSensorMotorola ||
        sensors[currentSensorKey] instanceof GeneralClasses.WFRSensorGalcon ||
        sensors[currentSensorKey] instanceof
          GeneralClasses.WFRSensorAquastride ||
        sensors[currentSensorKey] instanceof GeneralClasses.WFRSensorNDrip
      ) {
        sensorDictionary["manufacturer"] =
          sensors[currentSensorKey]["manufacturer"];
        sensorDictionary["programs"] = sensors[currentSensorKey]["programs"];
        sensorDictionary["valves"] = sensors[currentSensorKey]["valves"];
        sensorDictionary["sub_type"] = sensors[currentSensorKey]["sub_type"];
      }
      if (!sensors[currentSensorKey]["farming_unit_serial_number"]) {
        sensorDictionary["fert_meters"] =
          sensors[currentSensorKey]["fert_meters"];
      }
    } else if (
      sensors[currentSensorKey] instanceof GeneralClasses.PulseSensor
    ) {
      sensorDictionary["m3_per_pulse"] =
        sensors[currentSensorKey]["m3_per_pulse"];
      sensorDictionary["manufacturer"] =
        sensors[currentSensorKey]["manufacturer"];
    } else if (
      sensors[currentSensorKey] instanceof GeneralClasses.NDripPiezometer
    ) {
      sensorDictionary["manufacturer"] =
        sensors[currentSensorKey]["manufacturer"];
      sensorDictionary["should_consider_as_wfr"] =
        sensors[currentSensorKey]["should_consider_as_wfr"];
      sensorDictionary["offset"] = sensors[currentSensorKey]["offset"];
    } else if (
      sensors[currentSensorKey] instanceof GeneralClasses.SoilTempSensor
    ) {
      sensorDictionary["manufacturer"] =
        sensors[currentSensorKey]["manufacturer"];
      sensorDictionary["installation_depth"] =
        sensors[currentSensorKey]["installation_depth"];
    }

    let sensorSerialNumber = currentSensorKey;
    dict[sensorSerialNumber] = sensorDictionary;
  }

  return dict;
};

var getSoilDataObject = (field_object) => {
  return {
    initial_concentrations: {
      N_Concenration_Organic_Matter:
        field_object.initial_concentrations.N_Concenration_Organic_Matter,
      NH4_Concentration: field_object.initial_concentrations.NH4_Concentration,
      NO3_Concentration: field_object.initial_concentrations.NO3_Concentration,
    },
    soil_type: field_object.soil_type,
  };
};

const getIrrigationSystem = (irrigation_object) => {
  return {
    irrigation_type: irrigation_object.irrigation_type,
    irrigation_rate: irrigation_object.irrigation_rate,
    irrigation_hin: irrigation_object.irrigation_hin,
    tank_type: irrigation_object.tank_type,
    measured_irrigation_rate: irrigation_object.measured_irrigation_rate,
    fertigation_concentrations: irrigation_object.fertigation_concentrations,
    time_frames: irrigation_object.time_frames.map((timeFrame) => {
      return {
        day: timeFrame.day,
        start_hour: timeFrame.start_hour,
        end_hour: timeFrame.end_hour,
        min: parseFloat(timeFrame.min),
        max: parseFloat(timeFrame.max),
        active_period_start_date: timeFrame.active_period_start_date,
        active_period_end_date: timeFrame.active_period_end_date,
        name: timeFrame.name,
      };
    }),
  };
};

export var create_field = (distribution_id, farming_unit_id, field_object) => {
  return new Promise(function (resolve, reject) {
    var irrigationSystem = JSON.parse(
      JSON.stringify(getIrrigationSystem(field_object.irrigation_system)),
    );
    var sensors = JSON.parse(
      JSON.stringify(sensorObjectsToDictionary(field_object.sensors)),
    );

    // remove spaces from transmitter_id
    for (let sensorsKey in sensors) {
      sensors[sensorsKey].transmitter_id = sensors[
        sensorsKey
      ].transmitter_id.replace(/\s+/g, "");
    }

    var geo_data = { areas: field_object.geo_data.areas };
    let crop_data = JSON.parse(JSON.stringify(field_object.crop_data));
    crop_data.kc_table = {
      type: field_object.crop_data.kc_table.type,
      data: field_object.crop_data.kc_table.data[
        field_object.crop_data.kc_table.type
      ],
    };

    let soil_data = getSoilDataObject(field_object);

    axios
      .post(
        SCIROOT_FIELD_PATH + "/" + distribution_id + "/" + farming_unit_id,
        {
          field_name: field_object.name,
          last_days_temperature: 20,
          soil_type: field_object.soil_type,
          soil_data: soil_data,
          irrigation_system: irrigationSystem,
          sensors: sensors,
          cluster: field_object.cluster,
          crop_data: crop_data,
          geo_data: geo_data,
          installation_date: field_object.contract_data.installation_date,
          expiration_date: field_object.contract_data.expiration_date,
          settings: field_object.settings,
          farming_type: field_object.farming_type,
        },
      )
      .then(function (res) {
        resolve({
          fieldId: res.data["field_id"],
          fieldName: res.data["field_name"],
        });
      })
      .catch(function (e) {
        reject(e);
      });
  });
};


export var update_field = (distribution_id, farming_unit_id, field_object) => {
  return new Promise(function (resolve, reject) {
    var sensors = JSON.parse(
      JSON.stringify(sensorObjectsToDictionary(field_object.sensors)),
    );
    var irrigationSystem = JSON.parse(
      JSON.stringify(getIrrigationSystem(field_object.irrigation_system)),
    );

    // remove spaces from transmitter_id
    for (let sensorsKey in sensors) {
      sensors[sensorsKey].transmitter_id = sensors[
        sensorsKey
      ].transmitter_id.replace(/\s+/g, "");
    }

    var geo_data = {
      areas: field_object.geo_data.areas,
      meteorological_stations: field_object.geo_data.meteorological_stations,
      agriapi_polygon_ids: field_object.geo_data.agriapi_polygon_ids,
      area_size: field_object.geo_data.area_size,
    };

    let crop_data = JSON.parse(JSON.stringify(field_object.crop_data));
    crop_data.kc_table = {
      type: field_object.crop_data.kc_table.type,
      data: field_object.crop_data.kc_table.data[
        field_object.crop_data.kc_table.type
      ],
    };

    let soil_data = getSoilDataObject(field_object);

    axios
      .put(
        SCIROOT_FIELD_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id +
          "/" +
          field_object.id,
        {
          field_name: field_object.name,
          soil_type: field_object.soil_type,
          irrigation_system: irrigationSystem,
          sensors: sensors,
          cluster: field_object.cluster,
          crop_data: crop_data,
          soil_data: soil_data,
          geo_data: geo_data,
          installation_date: field_object.contract_data.installation_date,
          expiration_date: field_object.contract_data.expiration_date,
          farming_type: field_object.farming_type,
          settings: field_object.settings,
        },
      )
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        reject(e);
      });
  });
};

export var delete_field = (distribution_id, farming_unit_id, field_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        SCIROOT_FIELD_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id +
          "/" +
          field_id,
        {},
      )
      .then(function (res) {
        resolve("");
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export var addFieldRecord = (
  fieldId,
  startDate,
  recordType,
  value,
  extra = {},
) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(SCIROOT_FIELD_RECORDS_PATH + "/" + fieldId, {
        value: value,
        startDate: startDate,
        recordType: recordType,
        extra: extra,
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var loadFieldRecords = (
  fieldId,
  startDate,
  recordTypes,
  extra_data = [],
) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        SCIROOT_FIELD_RECORDS_PATH +
          "/" +
          fieldId +
          "/" +
          startDate +
          "?keys=" +
          recordTypes.join() +
          "&extra_data=" +
          extra_data.join(),
        {},
      )
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var deleteFieldRecord = (fieldId, startDate, recordType) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        SCIROOT_FIELD_RECORDS_PATH +
          "/" +
          fieldId +
          "?startDate=" +
          startDate +
          "&recordType=" +
          recordType,
        {},
      )
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export var isSupportingNitrogen = (field) => {
  if (field) {
    return field.settings.is_coupmodel_active == true;
  }

  return false;
};

export var shouldDisplayAccumulatedIrrigation = (field) => {
  if (field) {
    for (let sensor in field.sensors) {
      if (
        field.sensors[sensor] instanceof WFRSensor ||
        field.sensors[sensor] instanceof PulseSensor ||
        field.sensors[sensor] instanceof NDripPiezometer
      ) {
        return true;
      }
    }
    if (
      field.historical_data &&
      (field.historical_data["precipitation_h"] ||
        field.historical_data["planned"] ||
        field.historical_data["wfr_length"])
    ) {
      return true;
    }
    return isSupportingRollingFit(field);
  }
  return false;
};

export var getCurrentSeasonStartDate = (field) => {
  if (field) {
    let officialStartDate = moment(field.crop_data.sow_date);
    if (field.crop_data.irrigation_start_date !== "") {
      officialStartDate = moment(field.crop_data.irrigation_start_date);
    }
    let harvestDateMoment = moment();

    if (
      field.crop_data.harvest_date !== "" &&
      moment(field.crop_data.harvest_date) > harvestDateMoment
    ) {
      harvestDateMoment = moment(field.crop_data.harvest_date);
    }
    let todayMoment = moment();
    let closestDate = moment.min([harvestDateMoment, todayMoment]);
    let yearsDiff = moment
      .duration(closestDate.diff(officialStartDate))
      .asYears();

    // Add the integer part of the years difference to sow date
    let newDate = officialStartDate.add(Math.floor(yearsDiff), "years");
    return newDate;
  }
};

export var isSupportingRollingFit = (field) => {
  if (field && field.settings) {
    return field.settings.is_rolling_fit_active;
  }
  return false;
};

export var dashboardGraphToDisplay = (field) => {
  if (field) {
    for (let sensor in field.sensors) {
      if (field.sensors[sensor].type === "wp") {
        return "drop";
      }
    }

    if (field.settings.is_coupmodel_active === true) {
      return "nitrogen";
    } else if (shouldDisplayAccumulatedIrrigation(field)) {
      return "accumulated";
    }
  }
  return "drop";
};

export var historical_data_to_array = (historical_data) => {
  let returnArr = [];
  if (historical_data.hasOwnProperty("wtr")) {
    let wtr = Object.entries(historical_data["wtr"]);
    for (let value in wtr[0][1]) {
      returnArr.push({
        startDate: value,
        value: 1 * parseFloat(wtr[0][1][value]["value"]).toFixed(3),
        unit: wtr[0][1][value]["units"],
        type: "wtr",
        wtrType: wtr[0][1][value]["type"],
        note:
          wtr[0][1][value]["type"] === "cs"
            ? "WT_timing_recommendation_after_irrigation_starts"
            : "WT_timing_recommendation_last_hour",
      });
    }
  }
  if (historical_data.hasOwnProperty("fert")) {
    let fert = Object.entries(historical_data["fert"]);
    for (let value in fert[0][1]) {
      returnArr.push({
        startDate: value,
        value: 1 * parseFloat(fert[0][1][value]["value"]).toFixed(3),
        unit: fert[0][1][value]["units"],
        type: "fertilization",
        source: fert[0][1][value]["source"],
        fertAmount: 1 * parseFloat(fert[0][1][value]["nitroAmount"]).toFixed(3),
        fertType: fert[0][1][value]["fertType"],
      });
    }
  }
  if (historical_data.hasOwnProperty("precipitation_h")) {
    let precip = Object.entries(historical_data["precipitation_h"]);
    for (let value in precip[0][1]) {
      let duration =
        (parseFloat(precip[0][1][value]["endDate"]) - parseFloat(value)) /
        (60 * 60).toFixed(1);
      returnArr.push({
        type: "precipitation",
        startDate: value,
        endDate: precip[0][1][value]["endDate"],
        value: precip[0][1][value]["value"],
        unit: precip[1][1],
        source: precip[0][1][value]["source"],
        duration: duration,
      });
    }
  }
  if (historical_data.hasOwnProperty("precipitation_daily")) {
    let precip = Object.entries(historical_data["precipitation_daily"]);
    for (let value in precip[0][1]) {
      let duration =
        (parseFloat(precip[0][1][value]["endDate"]) - parseFloat(value)) /
        (60 * 60).toFixed(1);
      returnArr.push({
        type: "precipitation_daily",
        startDate: value,
        endDate: precip[0][1][value]["endDate"],
        value: precip[0][1][value]["value"],
        unit: precip[1][1],
        source: precip[0][1][value]["source"],
        duration: duration,
      });
    }
  }
  if (historical_data.hasOwnProperty("wfr_length")) {
    let wfrArr = Object.entries(historical_data["wfr_length"]);
    for (let value in wfrArr[0][1]) {
      let duration =
        (parseFloat(wfrArr[0][1][value]["endDate"]) - parseFloat(value)) /
        (60 * 60).toFixed(1);
      let manufacturer = wfrArr[0][1][value]["manufacturer"]
        ? "_" + wfrArr[0][1][value]["manufacturer"]
        : "";
      returnArr.push({
        startDate: value,
        endDate: wfrArr[0][1][value]["endDate"],
        duration: duration,
        value: wfrArr[0][1][value]["value"],
        source: wfrArr[0][1][value]["source"],
        unit: wfrArr[1][1],
        type: "irrigation",
        sensor_id: wfrArr[0][1][value]["sensor_id"],
        manufacturer: manufacturer,
      });
    }
  }
  if (historical_data.hasOwnProperty("discharge")) {
    let dischargeArr = Object.values(historical_data["discharge"]);
    dischargeArr.forEach((dischargeItem) => {
      returnArr.push({
        startDate: dischargeItem["date"],
        value: dischargeItem["value"],
        source: dischargeItem["source"],
        unit: "m³/h",
        type: "discharge",
        wfrValue: dischargeItem["wfrValue"] || undefined,
        wfrEndDate: dischargeItem["endDate"] || undefined,
        sensorId: dischargeItem["sensorId"] || undefined,
      });
    });
  }
  return returnArr;
};

export var getEventDateTitle = (startDate, endDate, timezone, sourceCode) => {
  let title = "";
  let midnightNow = moment().tz(timezone).endOf("day").unix();
  let midnightStart = moment
    .unix(parseInt(startDate))
    .tz(timezone)
    .endOf("day")
    .unix();
  let midnightEnd = midnightStart;
  if (endDate) {
    midnightEnd = moment
      .unix(parseInt(endDate))
      .tz(timezone)
      .endOf("day")
      .unix();
  }
  if (sourceCode === "reported") {
    if (
      moment
        .duration(moment(midnightNow).diff(moment(midnightStart)))
        .asDays() === 1
    ) {
      title = upper("yesterday");
    } else if (midnightNow == midnightStart) {
      title = upper("today");
    } else {
      title = " ";
    }
  } else {
    if (midnightNow > midnightEnd) {
      title = "";
    } else if (midnightNow == midnightStart) {
      title = upper("today");
    } else if (midnightStart < midnightNow && midnightNow < midnightEnd) {
      title = upper("today");
    } else {
      let timeSince = midnightEnd - midnightNow;

      if (timeSince == 60 * 60 * 24) {
        title = upper("tomorrow");
      } else {
        title = " ";
      }
    }
  }

  return title;
};

export var getEventDurationLine = (extraData) => {
  let durationLine = [];
  if ("duration" in extraData && extraData["duration"]) {
    if (extraData["duration"] % 1 !== 0) {
      let minutes = (
        (extraData["duration"] - Math.floor(extraData["duration"])) *
        60
      ).toFixed(0);
      let hours = Math.floor(extraData["duration"]).toFixed(0);
      if (hours > 0) {
        durationLine.push(hours.toString());
        durationLine.push(" hr ");
        durationLine.push(minutes.toString());
        durationLine.push(" min");
      } else {
        durationLine.push(minutes.toString());
        durationLine.push(" min");
      }
    } else {
      durationLine.push(
        Math.floor(extraData["duration"]).toFixed(0).toString(),
      );
      if (extraData["duration"] > 1) {
        durationLine.push(" hours");
      } else {
        durationLine.push(" hour");
      }
    }
  }
  return durationLine;
};

export var loadFieldHistory = (distribution_id, farming_unit_id, field_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        SCIROOT_FIELD_PATH +
          "/" +
          distribution_id +
          "/" +
          farming_unit_id +
          "/" +
          field_id,
        {
          field_id: field_id,
          farming_unit_id: farming_unit_id,
          distribution_id: distribution_id,
        },
      )
      .then(function (res) {
        resolve({ historicalData: res.data, field_id: field_id });
      })
      .catch(function (e) {
        console.log(e.message);
        reject(e);
      });
  });
};

export var deleteDischargeTestEvent = (event, selectedField) => {
  return new Promise(function (resolve, reject) {
    sensorsManager
      .deleteSensorRecord(
        selectedField.id,
        event.source,
        event.startDate,
        "discharge_test",
      )
      .then(function (res) {
        resolve({ status: "success" });
      })
      .catch(function (e) {
        console.log(e.message);
        reject({ status: "fail", error: e });
      });
  });
};

export var deleteWfrDischargeEvent = (event, selectedField) => {
  return new Promise(function (resolve, reject) {
    sensorsManager
      .deleteSensorRecordProperty(
        selectedField.id,
        event.sensorId,
        event.startDate,
        "wfr",
        "discharge",
      )
      .then(function (res) {
        resolve({ status: "success" });
      })
      .catch(function (e) {
        console.log(e.message);
        reject({ status: "fail", error: e });
      });
  });
};

export var deleteIrrigationEvent = (
  event,
  disableEventRecovery,
  selectedField,
) => {
  return new Promise(function (resolve, reject) {
    if (disableEventRecovery) {
      sensorsManager
        .addSensorRecord(
          selectedField.id,
          event.source,
          event.startDate,
          "wfr",
          0,
          { status: "invalid" },
        )
        .then((res) => {
          resolve({ status: "success" });
        })
        .catch(function (e) {
          console.log(e.message);
          reject({ status: "fail", error: e });
        });
    } else {
      sensorsManager
        .deleteSensorRecord(
          selectedField.id,
          event.source,
          event.startDate,
          "wfr",
        )
        .then(function (res) {
          resolve({ status: "success" });
        })
        .catch(function (e) {
          console.log(e.message);
          reject({ status: "fail", error: e });
        });
    }
  });
};

export var deletePrecipitationEvent = (
  event,
  disableEventRecovery,
  selectedField,
) => {
  let deletePrecipitation;

  if (event?.source === "manual") {
    deletePrecipitation = deleteFieldRecord;
    event.source = selectedField.id;
  } else {
    deletePrecipitation = weatherManager.deleteWeatherRecord;
  }
  return new Promise(function (resolve, reject) {
    deletePrecipitation(event.source, event.startDate, "precipitation_h")
      .then(function (res) {
        resolve({ status: "success" });
      })
      .catch(function (e) {
        console.log(e.message);
        reject({ status: "fail", error: e });
      });
  });
};

export var deleteFertilizationEvent = (event, selectedField) => {
  return new Promise(function (resolve, reject) {
    deleteFieldRecord(selectedField.id, event.startDate, "fert")
      .then(function (res) {
        resolve({ status: "success" });
      })
      .catch(function (e) {
        console.log(e.message);
        reject({ status: "fail", error: e });
      });
  });
};

export var deleteWtrEvent = (event, selectedField) => {
  return new Promise(function (resolve, reject) {
    deleteFieldRecord(selectedField.id, event.startDate, "wtr")
      .then(function (res) {
        resolve({ status: "success" });
      })
      .catch(function (e) {
        console.log(e.message);
        reject({ status: "fail", error: e });
      });
  });
};

// A function for analytics requirements, to support when a field is in its early days
export var isFieldEarlyDays = (field) => {
  const EARLY_DAYS_THRESHOLD = 14;
  let isFieldEarlyDays = false;

  if (field) {
    if (
      moment().diff(getCurrentSeasonStartDate(field), "days") <
      EARLY_DAYS_THRESHOLD
    ) {
      isFieldEarlyDays = true;
    }
  }

  return isFieldEarlyDays;
};

export var getIfThisFieldSupportFert = (fieldSensors, farmingUnitSensors) => {
  let fieldSupportFert = false;
  for (let sensor in fieldSensors) {
    if (fieldSensors[sensor].manufacturer === "tal-gil") {
      for (let farmingSensor in farmingUnitSensors) {
        if (
          farmingUnitSensors[farmingSensor].transmitter_id ===
            fieldSensors[sensor].transmitter_id &&
          farmingUnitSensors[farmingSensor].fert_meters &&
          Object.keys(farmingUnitSensors[farmingSensor].fert_meters).length > 0
        ) {
          fieldSupportFert = true;
        }
      }
    }
  }
  return fieldSupportFert;
};

export const calculateDaysFromSeasonStart = (sow_date) => {
  if (sow_date) {
    const sowDateMoment = moment(sow_date);
    const currentDate = moment();
    if (currentDate.isBefore(sowDateMoment)) {
      return 0;
    } else {
      return currentDate.diff(
        getRelevantCyclicDateByDate(currentDate, sowDateMoment),
        "days",
      );
    }
  }
};

