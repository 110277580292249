var axios = require("axios");
var GeneralClasses = require("@model/GeneralClasses");
var { error_message } = require("@managers/helpers");
var moment = require("moment");

const SCIROOT_SOIL_DATA_PATH = "soil";

export var soil_data = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(SCIROOT_SOIL_DATA_PATH, {})
      .then(function (res) {
        var soil_data = new GeneralClasses.SoilData(res.data);
        resolve(soil_data);
      })
      .catch(function (err) {
        console.log(error_message(err));
        reject(err);
      });
  });
};

export const calculateSoilTemperatureValues = (soilTemperature) => {
  let last24Hours = moment().subtract(1, "d").unix();
  let sum = 0;
  let count = 0;
  let max = 0;
  for (let key in soilTemperature) {
    if (key >= last24Hours) {
      if (soilTemperature[key] > max) {
        max = soilTemperature[key];
      }
      sum += soilTemperature[key];
      count += 1;
    }
  }
  // return "N/A" if no data
  if (count === 0) {
    return { average: "N/A", max: "N/A" };
  }
  // return the average 2 decimal points and max_value
  return { average: (sum / count).toFixed(2), max: max };
};
