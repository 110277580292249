var React = require('react');
var { ComplexSelectMenu, SelectMenu } = require('SelectMenu');
var { upper } = require('helpers');
const { useTranslation, withTranslation } = require('react-i18next');
var ValvesInput = require('ValvesInput');
const { useState, useEffect, useCallback } = require('react');
const { connect } = require('react-redux');
const moment = require('moment/moment');
const EditExtraSensorContent = require('./EditExtraSensorContent');
const {
  FieldModalMenuOptions,
  FarmingUnitModalMenuOptions,
} = require('constants/menuOptions');

const EditSensor = ({
  type,
  sub_type,
  valves,
  transmitter_id,
  coordinates,
  m3_per_pulse,
  manufacturer,
  onCancel,
  onDone,
  existingFieldDisableType,
  name,
  userData,
  offset,
  fert_meters,
  farmingUnitModal,
  farmingUnitSensors,
  farming_unit_serial_number,
  installation_depth,
}) => {
  const { t } = useTranslation();
  let initialType =
    type === 'pulse' ||
    type === 'piezometer' ||
    type === 'wp' ||
    type === 'soil_temp'
      ? { type: type }
      : sub_type
        ? {
            type: 'ndrip wm',
            subType: sub_type,
          }
        : farmingUnitModal
          ? { type: 'tal-gil' }
          : { type: manufacturer || 'aquastride' };
  const [chosenType, setChosenType] = useState(
    initialType || { type: 'aquastride' },
  );
  const [chosenTransmitterID, setChosenTransmitterID] = useState(
    transmitter_id || '',
  );
  const [chosenTalGilTransmitterID, setChosenTalGilTransmitter] = useState(
    farming_unit_serial_number || '',
  );
  const [chosenLatitude, setChosenLatitude] = useState(
    coordinates?.latitude || '',
  );
  const [chosenLongitude, setChosenLongitude] = useState(
    coordinates?.longitude || '',
  );
  const [chosenValves, setChosenValves] = useState(valves);
  const [chosenM3PerPulse, setChosenM3PerPulse] = useState(m3_per_pulse);
  const [chosenName, setChosenName] = useState(name || '');
  const [chosenOffset, setChosenOffset] = useState(offset || 0);
  const [chosenInstallationDepth, setChosenInstallationDepth] = useState(
    installation_depth || 0,
  );
  const [talGilSensorFertilizers, setTalGilSensorFertilizers] = useState({});

  useEffect(() => {
    if (fert_meters) {
      setTalGilSensorFertilizers(fert_meters);
    }
  }, [fert_meters]);

  const changeTypeHandler = useCallback((newType) => {
    setChosenType(newType);
    setTalGilSensorFertilizers({});
    setChosenTalGilTransmitter('');
    setChosenLatitude('');
    setChosenName('');
    setChosenLongitude('');
    setChosenTransmitterID('');
  }, []);

  // extra content handlers

  const removeExtraContentRow = useCallback(
    (fertilizerId, index) => {
      const newFertMeters = { ...talGilSensorFertilizers };
      newFertMeters[fertilizerId].splice(index, 1);
      setTalGilSensorFertilizers(newFertMeters);
    },
    [talGilSensorFertilizers],
  );

  const onFertilizerRowStartDayChange = useCallback(
    (fertilizerId, index, value) => {
      var formattedDate = moment(value).unix();
      const newFertMeters = { ...talGilSensorFertilizers };
      newFertMeters[fertilizerId][index]['start_date'] = formattedDate;
      setTalGilSensorFertilizers(newFertMeters);
    },
    [talGilSensorFertilizers],
  );

  const onFertilizerIDChange = useCallback(
    (fertilizerId, index, value) => {
      const newFertMeters = { ...talGilSensorFertilizers };

      // check if new fertilizer id is already in the list
      if (newFertMeters[value]) {
        let oldFertilizerId = newFertMeters[fertilizerId][index];
        newFertMeters[value].push(oldFertilizerId);
        newFertMeters[fertilizerId].splice(index, 1);
      } else {
        newFertMeters[value] = [{ ...newFertMeters[fertilizerId][index] }];
        newFertMeters[fertilizerId].splice(index, 1);
        if (newFertMeters[fertilizerId].length === 0) {
          delete newFertMeters[fertilizerId];
        }
      }
      setTalGilSensorFertilizers(newFertMeters);
    },
    [talGilSensorFertilizers],
  );

  const oncFertilizerTypeChange = useCallback(
    (fertilizerId, index, value) => {
      const newFertMeters = { ...talGilSensorFertilizers };
      let currentSensor = newFertMeters[fertilizerId];
      currentSensor[index]['type'] = value;
      setTalGilSensorFertilizers(newFertMeters);
    },
    [talGilSensorFertilizers],
  );

  const onAddFertilizerClick = () => {
    const newFertMeter = {
      type: 'no3',
      start_date: '',
    };
    const newFertMeters = { ...talGilSensorFertilizers };

    if (!newFertMeters['']) {
      newFertMeters[''] = [newFertMeter];
    } else {
      return;
    }
    setTalGilSensorFertilizers(newFertMeters);
  };

  const onNewTalGilFieldSensor = (farmingUnitSerialNumber) => {
    let inheritFarmingUnitSensor = farmingUnitSensors[farmingUnitSerialNumber];
    setTalGilSensorFertilizers(inheritFarmingUnitSensor.fert_meters);
    setChosenTransmitterID(inheritFarmingUnitSensor.transmitter_id);
    setChosenLatitude(inheritFarmingUnitSensor.coordinates.latitude || '');
    setChosenLongitude(inheritFarmingUnitSensor.coordinates.longitude || '');
    setChosenName(inheritFarmingUnitSensor.name);
    setChosenTalGilTransmitter(farmingUnitSerialNumber);
  };

  const valvesHandler = (newValves) => {
    setChosenValves(newValves);
  };

  const renderFertilizerRows = () => {
    return Object.keys(talGilSensorFertilizers).map((key) => {
      return talGilSensorFertilizers[key].map((fert_meter, index) => {
        return (
          <EditExtraSensorContent
            key={index}
            chosenFertType={fert_meter.type}
            onChangeFertType={(fertilizerType) =>
              oncFertilizerTypeChange(key, index, fertilizerType.type)
            }
            chosenFertID={key}
            chosenStartDate={fert_meter.start_date}
            onFertilizerRowStartDayChange={(start_date) =>
              onFertilizerRowStartDayChange(key, index, start_date)
            }
            onFertilizerRowRemove={() => removeExtraContentRow(key, index)}
            onFertilizerIDChange={(newFertID) =>
              onFertilizerIDChange(key, index, newFertID)
            }
            readOnly={chosenTalGilTransmitterID}
          />
        );
      });
    });
  };

  const middleColTitle =
    !farmingUnitModal &&
    ['motorola', 'tal-gil', 'galcon', 'aquastride'].includes(chosenType.type)
      ? t('valves')
      : chosenType.type === 'pulse'
        ? t('water_meter_units')
        : chosenType.type === 'piezometer'
          ? `${t('offset')} (${userData?.length})`
          : chosenType.type === 'soil_temp'
            ? `${t('installation_depth')} (${userData?.length})`
            : '';
  const middleColContent =
    middleColTitle === t('valves') ? (
      <ValvesInput onAdd={valvesHandler} valves={chosenValves} />
    ) : middleColTitle === t('water_meter_units') ? (
      <div style={{ lineHeight: 1 }}>
        <input
          className='form-control w-100'
          value={chosenM3PerPulse}
          onInput={(e) => setChosenM3PerPulse(e.target.value)}
        />
      </div>
    ) : middleColTitle === `${t('offset')} (${userData?.length})` ? (
      <div style={{ lineHeight: 1 }}>
        <input
          type='number'
          className='form-control w-100'
          value={chosenOffset}
          onInput={(e) => setChosenOffset(e.target.value)}
        />
      </div>
    ) : middleColTitle ===
      `${t('installation_depth')} (${userData?.length})` ? (
      <div style={{ lineHeight: 1 }}>
        <input
          type='number'
          className='form-control w-100'
          value={chosenInstallationDepth}
          onInput={(e) => setChosenInstallationDepth(e.target.value)}
        />
      </div>
    ) : null;

  const readOnly = chosenType.type === 'tal-gil' && chosenTalGilTransmitterID;

  return (
    <div className='d-flex mb-4 card bg-grey96'>
      <div
        className=' d-flex justify-content-between flex-grow-1 card-body  flex-row bg-grey96'
        style={{ padding: '12px 16px' }}
      >
        <div
          className='text-grey45'
          style={{ width: '136px', marginRight: '16px' }}
        >
          <label className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45 line-height-1'>
            {upper(t('type'))}
          </label>
          <div className='line-height-1'>
            <ComplexSelectMenu
              existingFieldDisableType={existingFieldDisableType}
              onChange={(newType) => changeTypeHandler(newType)}
              options={
                farmingUnitModal
                  ? FarmingUnitModalMenuOptions
                  : FieldModalMenuOptions
              }
              curValue={chosenType || manufacturer || type}
            />
          </div>
        </div>
        <div
          className='text-grey45'
          style={{ width: '136px', marginRight: '16px' }}
        >
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('transmitter_id')}
          </label>
          <div style={{ lineHeight: 1 }}>
            {chosenType.type === 'tal-gil' &&
            farmingUnitSensors &&
            !farmingUnitModal ? (
              <SelectMenu
                menuStyle={{
                  height: 40,
                  borderWidth: 1,
                  borderColor: 'grey91',
                  fontSize: 14,
                }}
                options={Object.keys(farmingUnitSensors).map(
                  (sensorSerialNumber) => ({
                    title:
                      farmingUnitSensors[sensorSerialNumber].transmitter_id,
                    value: sensorSerialNumber,
                  }),
                )}
                onChange={onNewTalGilFieldSensor}
                curValue={chosenTalGilTransmitterID}
                placeholder={t('please select')}
                sortBox={'sortBox'}
              />
            ) : (
              <input
                className='form-control w-100'
                type='text'
                value={chosenTransmitterID}
                onChange={(e) =>
                  setChosenTransmitterID(e.target.value.toLowerCase())
                }
              />
            )}
          </div>
        </div>
        <div
          className='text-grey45'
          style={{ width: '120px', marginRight: '16px' }}
        >
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('name')}
          </label>
          <div style={{ lineHeight: 1 }}>
            <input
              className='form-control w-100'
              type='text'
              value={chosenName}
              onChange={(e) => setChosenName(e.target.value)}
              disabled={readOnly}
            />
          </div>
        </div>
        <div
          className='text-grey45 w-136'
          style={{ width: '136px', marginRight: '16px' }}
        >
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {middleColTitle}
          </label>
          <div style={{ lineHeight: 1 }}>{middleColContent}</div>
        </div>
        <div className='text-grey45' style={{ width: '136px' }}>
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('latitude')}
          </label>
          <div className='w-100' style={{ lineHeight: 1 }}>
            <input
              className='form-control w-100'
              type='number'
              value={chosenLatitude}
              onChange={(e) => setChosenLatitude(e.target.value)}
              disabled={readOnly}
            />
          </div>
          <label
            style={{ lineHeight: 1 }}
            className='font-weight-semi-bold mb-0 text-size-14 lh-1 text-grey45'
          >
            {t('longitude')}
          </label>
          <div className='w-100' style={{ lineHeight: 1, width: 136 }}>
            <input
              className='form-control w-100'
              type='number'
              value={chosenLongitude}
              onChange={(e) => setChosenLongitude(e.target.value)}
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
      {renderFertilizerRows()}
      {chosenType.type === 'tal-gil' &&
        !chosenTalGilTransmitterID &&
        farmingUnitModal && (
          <div
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              padding: '12px 16px',
            }}
            className='d-flex justify-content-start align-items-center text-grey45 font-weight-semi-bold text-size-16 noHoverEvents'
            onClick={onAddFertilizerClick}
          >
            <img
              style={{ marginRight: '4px' }}
              src={require('images/icons/plus_icon_grey_45.svg')}
              alt='New Phase'
            />

            {t('add_fertilizer')}
          </div>
        )}
      {chosenTalGilTransmitterID && !farmingUnitModal && (
        <div
          style={{
            display: 'flex',
            margin: '0 16px',
            padding: '12px 16px',
            alignItems: 'center',
            gap: 'var(--Stsndard-scale-Base, 8px)',
            alignSelf: 'stretch',
            borderRadius: 'var(--Stsndard-scale--02, 4px)',
            background: 'var(--Grey-91, #DCE0E8)',
            color: 'var(--Grey-45, #4D5674)',
            fontFamily: '"Source Sans Pro", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
          }}
        >
          <img
            src={require('images/icons/info_icon.svg')}
            alt='Info icon'
            style={{ width: '14px', height: '14px' }}
          />
          <span>{t('Farming_unit_entity_tooltip')}</span>
        </div>
      )}
      <div className='d-flex flex-row align-items-center w-100 my-2'>
        <button
          style={{ outline: 'none', width: 80, height: 24, fontWeight: '600' }}
          type='button'
          className='btn btn-outline-secondary d-flex mr-2 ml-auto justify-content-center align-items-center text-size-12'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          style={{ outline: 'none', width: 80, height: 24, fontWeight: '600' }}
          type='button'
          className={
            'btn d-flex mr-3 justify-content-center align-items-center bg-blue-primary text-white text-size-12'
          }
          onClick={() =>
            onDone(
              chosenTransmitterID,
              chosenLatitude,
              chosenLongitude,
              chosenM3PerPulse,
              chosenValves,
              chosenType,
              chosenName,
              chosenOffset,
              talGilSensorFertilizers,
              chosenInstallationDepth
            )
          }
        >
          Done
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
  };
};

module.exports = connect(mapStateToProps)(withTranslation()(EditSensor));
