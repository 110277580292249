import moment from "moment-timezone";
import { getRelevantCyclicDateByDate } from "./helpers";

export var transformKCTableDataToDisplayData = (
  KCTable,
  sowDate,
  currentGDD = undefined,
) => {
  if (!KCTable || KCTable?.type === "byMonth") return;
  let phasesType = KCTable.type;
  let transformedData = {
    phasesType: phasesType,
    phasesData: [],
  };
  let total = 0;
  let start = 0;
  let end = 0;
  let isStressActive = false;
  let name = "";
  let data =
    phasesType === "byGrowthPhase"
      ? KCTable.data.byGrowthPhase
      : KCTable.data.byGrowthPhaseGDD;
  let currentMoment = moment();
  let fieldStartDate = getRelevantCyclicDateByDate(
    currentMoment,
    moment(sowDate),
  );
  transformedData.fieldStartDate = fieldStartDate;
  let daysFromFieldStartedToToday = Math.round(
    (currentMoment.unix() - fieldStartDate.unix()) / (60 * 60 * 24),
  );
  let currentPhaseFunction;

  switch (phasesType) {
    case "byGrowthPhaseGDD":
      for (let i = 0; i < data.length; i++) {
        total = parseFloat(data[i].duration);
        name = data[i].name;
        isStressActive = data[i].hasOwnProperty("stress")
          ? data[i].stress.is_stress_active
          : false;
        end = start + total;
        transformedData.phasesData.push({
          name,
          total,
          start,
          end,
          isStressActive,
        });
        start = end;
      }
      currentPhaseFunction = getCurrentPhaseByGDD;
      transformedData.valueFromSeasonStart = currentGDD;
      break;
    case "byGrowthPhase":
      let startDate = moment(fieldStartDate);
      for (let i = 0; i < data.length; i++) {
        total = parseFloat(data[i].duration);
        name = data[i].name;
        isStressActive = data[i].hasOwnProperty("stress")
          ? data[i].stress.is_stress_active
          : false;
        start = startDate.format("YYYY, MMM DD");
        end = startDate.add(total, "days").format("YYYY, MMM DD");
        transformedData.phasesData.push({
          name,
          total,
          start,
          end,
          isStressActive,
        });
        startDate = moment(end);
      }
      currentPhaseFunction = getCurrentPhaseByDays;
      transformedData.valueFromSeasonStart = daysFromFieldStartedToToday;
      break;
  }
  let totalAccumulatedValue = transformedData?.phasesData.reduce(
    (acc, phase) => acc + phase.total,
    0,
  );

  transformedData.currentPhaseName = currentPhaseFunction(
    currentMoment.unix(),
    moment(sowDate).unix(),
    totalAccumulatedValue,
    transformedData.valueFromSeasonStart,
    transformedData.phasesData,
  );
  transformedData.totalAccumulatedValue = totalAccumulatedValue;
  transformedData.endOfSeasonValue = Math.round(
    totalAccumulatedValue - transformedData.valueFromSeasonStart,
  );
  transformedData.nowPosition =
    (transformedData.valueFromSeasonStart / totalAccumulatedValue) * 100;
  return transformedData;
};

export var getCurrentPhaseByDays = (
  currentDate,
  sowDate,
  totalDays,
  daysFromSeasonStart,
  phasesData,
) => {
  let phaseString = "";
  // check for pre-season case
  let dateOfAccumulation = moment.unix(sowDate).add(totalDays, "days").unix();
  if (sowDate > currentDate) {
    phaseString = "pre-season";
  } else if (currentDate > dateOfAccumulation) {
    phaseString = "between-seasons";
  } else {
    phasesData.forEach((phase) => {
      if (
        moment
          .unix(currentDate)
          .isBetween(moment(phase.start), moment(phase.end))
      ) {
        phaseString = phase.name;
      }
    });
  }
  return phaseString;
};

export var getCurrentPhaseByGDD = (
  current_date,
  sow_date,
  AccumulatedGDD,
  GDDFromSeasonStart,
  phasesData,
) => {
  let phaseString = "";
  // check for pre-season case
  if (sow_date > current_date) {
    phaseString = "pre-season";
  } else if (GDDFromSeasonStart > AccumulatedGDD) {
    phaseString = "between-seasons";
  } else {
    phasesData.forEach((phase) => {
      if (GDDFromSeasonStart >= phase.start && GDDFromSeasonStart < phase.end) {
        phaseString = phase.name;
      }
    });
  }
  return phaseString;
};
