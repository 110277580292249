var React = require('react');
var { connect } = require('react-redux');
var {
  getFieldArea,
  calculateDaysFromSeasonStart,
  last_kc,
} = require('fieldManager');

const { calculateSoilTemperatureValues } = require('soilDataManager');
const FieldDetailsMetricBox = require('FieldDetailsMetricBox');
var GeneralClasses = require('GeneralClasses');
var weatherManager = require('weatherManager');
var {
  upper,
  latestObjectfromDict,
  latestObjectFromDictWithKey,
  timeSinceNowString,
} = require('helpers');
var moment = require('moment');
var { withTranslation } = require('react-i18next');
var { unitDisplayName, convert } = require('unitsManager');
var {
  get_configuration_config,
  FeatureFlagDictionary,
} = require('remoteConfigurationsManager');
var IrrigationMeterDetailsContainer = require('IrrigationMeterDetailsContainer');
const PhasesContainer = require('PhasesContainer');

const MainCSS = require('mainCSS');

class ExtendedFieldDetails extends React.Component {
  constructor(props) {
    super(props);
    this.calculateArea = this.calculateArea.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  calculateArea(areas) {
    // calculate area
    let fieldArea = getFieldArea(areas);
    let fieldAreaConverted = convert(
      fieldArea,
      'dunam',
      this.props.userData.area,
      'area',
    );
    return fieldAreaConverted;
  }

  render() {
    const { t } = this.props;
    let cropTitle = '';
    let lastET0Value = '0';
    let lastKC = '0';
    let expirationDate = '';
    let soilType = '';
    let fieldArea = '';
    let accumulatedGDD = '';
    let accumulatedGDDValue = 0;
    let fert_height = '';
    let fert_last_seen = '';
    let fert_volume = '';
    let daysInSeason = '';
    let SoilTemperatureValues = '';
    let lastSoilTemperatureDate = '';
    let lastSoilAverageTemperature = '';
    let lastSoilMaxTemperature = '';
    let soilTempSensorsList = [];
    if (
      this.props.selectedField &&
      this.props.selectedField.crop_data.sow_date
    ) {
      daysInSeason = calculateDaysFromSeasonStart(
        this.props.selectedField.crop_data.sow_date,
      );
    }
    let alert = <div></div>;
    let phenologicalPhasesFeatureFlag = get_configuration_config(
      this.props.configuration_data,
      FeatureFlagDictionary.PHENOLOGICAL_PHASES,
      this.props.selectedFarmingUnit?.name,
      this.props.selectedDistribution?.name,
    );

    if (this.props.selectedField) {
      if (this.props.selectedField.geo_data.area_size === undefined) {
        fieldArea = this.calculateArea(this.props.selectedField.geo_data.areas);
      } else {
        fieldArea = this.props.selectedField.geo_data.area_size;
      }

      if (this.props.selectedField.crop_data.crop_variety !== '') {
        cropTitle =
          upper(t(this.props.selectedField.crop_data.crop_type)) +
          '/' +
          upper(this.props.selectedField.crop_data.crop_variety);
      } else {
        cropTitle = upper(t(this.props.selectedField.crop_data.crop_type));
      }

      soilTempSensorsList = Object.keys(
        this.props.selectedField.sensors,
      ).filter(
        (currentSensor) =>
          this.props.selectedField.sensors[currentSensor] instanceof
          GeneralClasses.SoilTempSensor,
      );
      if (
        soilTempSensorsList.length > 0 &&
        this.props.selectedField.historical_data?.soil_temp?.values
      ) {
        [lastSoilTemperatureDate, SoilTemperatureValues] =
          latestObjectFromDictWithKey(
            this.props.selectedField.historical_data?.soil_temp?.values,
          );
        lastSoilTemperatureDate = timeSinceNowString(
          moment.unix(lastSoilTemperatureDate),
        );

        if (SoilTemperatureValues === undefined) {
          SoilTemperatureValues = 'N/A';
        }
        let lastSoilAverageValues = calculateSoilTemperatureValues(
          this.props.selectedField.historical_data.soil_temp?.values,
        );
        lastSoilAverageTemperature = lastSoilAverageValues.average;
        lastSoilMaxTemperature = lastSoilAverageValues.max;

        // combine all values in one str
        SoilTemperatureValues =
          lastSoilAverageTemperature +
          ' / ' +
          lastSoilMaxTemperature +
          ' / ' +
          SoilTemperatureValues;
      }
      if (
        this.props.selectedField.historical_data &&
        'et0' in this.props.selectedField.historical_data
      ) {
        let et0Statistics = weatherManager.weatherDataStatistics(
          this.props.selectedField.historical_data.et0.values,
          this.props.selectedField.geo_data.time_zone,
        );

        let startOfDay = moment()
          .tz(this.props.selectedField.geo_data.time_zone)
          .startOf('day')
          .unix();

        // in case we dont have today value, we will show yesterdays
        let yesterdayStartOfDay = moment()
          .tz(this.props.selectedField.geo_data.time_zone)
          .startOf('day')
          .subtract(1, 'd')
          .unix();

        if (startOfDay in et0Statistics) {
          // Taking the last value of the day
          lastET0Value = convert(
            parseFloat(et0Statistics[startOfDay]['lastValue']).toFixed(2),
            'mm',
            this.props.selectedField.historical_data.etc?.units,
            'length',
          );
        } else if (yesterdayStartOfDay in et0Statistics) {
          lastET0Value = convert(
            parseFloat(et0Statistics[yesterdayStartOfDay]['lastValue']).toFixed(
              2,
            ),
            'mm',
            this.props.selectedField.historical_data.etc?.units,
            'length',
          );
        }

        var lastKCResult = last_kc(this.props.selectedField);
        lastKC = parseFloat(lastKCResult[0]).toFixed(2);
      }

      expirationDate = moment
        .unix(this.props.selectedField.contract_data.expiration_date)
        .format('MMM Do YYYY');

      soilType = this.props.selectedField.soil_type;

      if (
        this.props.selectedField.historical_data &&
        this.props.selectedField.historical_data.accu_gdd
      ) {
        accumulatedGDDValue = latestObjectfromDict(
          this.props.selectedField.historical_data.accu_gdd.values,
        );
        accumulatedGDD =
          accumulatedGDDValue +
          ' ' +
          unitDisplayName(
            this.props.selectedField.historical_data.accu_gdd.units,
          );
      }

      if (
        get_configuration_config(
          this.props.configuration_data,
          FeatureFlagDictionary.FERT_TANK_HEIGHT,
          this.props.selectedFarmingUnit.name,
          this.props.selectedDistribution.name,
        ) &&
        this.props.selectedField.historical_data &&
        this.props.selectedField.historical_data.fert_height &&
        Object.keys(this.props.selectedField.historical_data.fert_height.values)
          .length > 0
      ) {
        //TODO: this is hard codded to cm, as it a POC and the algorithm calculate for cm only
        let [date, value] = latestObjectFromDictWithKey(
          this.props.selectedField.historical_data.fert_height.values,
        );
        fert_last_seen = timeSinceNowString(moment.unix(date));
        fert_height = parseFloat(value).toFixed(2) + ' cm';
      }
      if (
        get_configuration_config(
          this.props.configuration_data,
          FeatureFlagDictionary.FERT_TANK_VOLUME,
          this.props.selectedFarmingUnit.name,
          this.props.selectedDistribution.name,
        ) &&
        this.props.selectedField.historical_data &&
        this.props.selectedField.historical_data.fert_volume &&
        Object.keys(this.props.selectedField.historical_data.fert_volume.values)
          .length > 0
      ) {
        //TODO: this is hard codded to cm, as it a POC and the algorithm calculate for cm only
        fert_volume =
          parseFloat(
            latestObjectfromDict(
              this.props.selectedField.historical_data.fert_volume.values,
            ),
          ).toFixed(2) + ' L';
      }
    }

    const metrics = [
      {
        condition: true,
        label: 'crop_type',
        value: cropTitle,
        unit: '',
      },
      {
        condition: true,
        label: 'area',
        value: fieldArea,
        unit: unitDisplayName(this.props.userData.area),
      },
      {
        condition:
          this.props.field_state === 'active' && lastKC && !isNaN(lastKC),
        label: 'kc',
        value: lastKC,
        unit: '',
      },
      {
        condition: this.props.field_state === 'active' && lastET0Value,
        label: 'et0',
        value: lastET0Value,
        unit: unitDisplayName(this.props.userData.length),
      },
      {
        condition: true,
        label: 'soil_type',
        value: t(upper(soilType)),
        unit: '',
      },
      {
        condition: soilTempSensorsList.length > 0,
        label: 'soil_temperature_values',
        value: SoilTemperatureValues,
        unit:
          SoilTemperatureValues !== 'N/A'
            ? unitDisplayName(this.props.userData.temperature)
            : '',
        lastSeen: lastSoilTemperatureDate,
        appearWithEmptyValue: true,
      },
      {
        condition: accumulatedGDD,
        label: 'gdd',
        value: accumulatedGDD,
        unit: '',
      },
      {
        condition: fert_height && fert_height !== '',
        label: 'fert_tank_height',
        value: fert_height,
        unit: '',
        lastSeen: fert_last_seen,
      },
      {
        condition: fert_volume && fert_volume !== '',
        label: 'Fert_tank_volume',
        value: fert_volume,
        unit: '',
        lastSeen: fert_last_seen,
      },
      {
        condition:
          this.props.field_state === 'active' && daysInSeason !== undefined,
        label: 'Field_details_days_in_season',
        value: daysInSeason + ' ' + t('days'),
        unit: '',
      },
      {
        condition: true,
        label: 'expiration_date',
        value: expirationDate,
        unit: '',
      },
    ];
    return (
      <div>
        <div
          className='d-flex flex-row bg-white w-100 fieldDetails fieldDetailsLargeScreens'
          style={{
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            lineHeight: '40px',
          }}
        >
          <div
            className='d-flex align-items-center IrrigationMeterDetailsContainer'
            style={{
              borderLeft: '1px solid #DCE0E8',
              borderBottom: '0.5px solid #DCE0E8',
              borderTop: '0.5px solid #DCE0E8',
              borderTopLeftRadius: '14px',
            }}
          >
            <IrrigationMeterDetailsContainer />
          </div>
          <div
            className='d-flex justify-content-around flex-wrap w-100 FieldDetailsContainer'
            style={{
              borderRight: '1px solid #DCE0E8',
              borderTop: '0.5px solid #DCE0E8',
            }}
          >
          {metrics.map(
            ({ condition, label, value, unit, lastSeen }, index) =>
              condition && (
                <FieldDetailsMetricBox
                  key={index}
                  label={label}
                  value={value}
                  unit={unit}
                  fieldState={this.props.field_state}
                  lastSeen={lastSeen}
                />
              ),
          )}
        </div>
          {alert}
        </div>
        {phenologicalPhasesFeatureFlag && <PhasesContainer />}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
    selectedField: state.distribution_data.selected_entities.field,
    loadHistoryStatus: state.distribution_data.field_load_history,
    field_state: state.distribution_data.field_state,
    configuration_data: state.configuration_data,
    selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
    selectedDistribution:
      state.distribution_data.selected_entities.distribution,
  };
};

const mapDispatchToProps = {};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ExtendedFieldDetails));
