export var unitDisplayName = (unitName) => {
  if (!unitName) {
    return "";
  }

  const displayNames = {
    celsius: "°C",
    fahrenheit: "°F",
    "cubic metre": "m³",
    "mega liter": "Megaliter",
    hectare: "Ha",
    bushel: "bsh",
    gallon: "Gal",
    liter: "L",
    kg: "Kg",
    "acre foot": "AF",
    "acre inch": "ac⋅inch",
    "kg/hectare": "Kg/Ha",
    "lb/hectare": "lb/Ha",
    "short ton/hectare": "ton/Ha",
    "tonne/hectare": "Tonne/Ha",
    centimetre: "cm",
    "acre foot/acre": "AF/A",
  };

  if (unitName.toLowerCase() in displayNames) {
    return displayNames[unitName.toLowerCase()];
  } else {
    return unitName;
  }
};

export var convert = (value, fromUnit, toUnit, type) => {
  if (type && fromUnit && toUnit && value) {
    let finalValue = undefined;

    if (type.toLowerCase() == "area") {
      let dunamRate = parseFloat(
        {
          dunam: 1.0,
          hectare: 10.0,
          acre: 4.047,
        }[fromUnit.toLowerCase()],
      );

      let newDunamRate = parseFloat(
        {
          dunam: dunamRate,
          hectare: dunamRate / 10.0,
          acre: dunamRate / 4.047,
        }[toUnit.toLowerCase()],
      );

      finalValue = value * newDunamRate;
    } else if (type.toLowerCase() == "length") {
      let mmRate = parseFloat(
        {
          mm: 1.0,
          inch: 25.4,
        }[fromUnit.toLowerCase()],
      );

      let newmmRate = parseFloat(
        {
          mm: mmRate,
          inch: mmRate / 25.4,
        }[toUnit.toLowerCase()],
      );

      finalValue = value * newmmRate;
    } else if (type.toLowerCase() == "volume") {
      let cubicMeterRate = parseFloat(
        {
          "cubic metre": 1.0,
          gallon: 1 / 264.172,
          bushel: 1 / 28.3776,
          "acre foot": 1233.0,
          "acre inch": 102.79,
          megaliter: 1000,
        }[fromUnit.toLowerCase()],
      );

      let newUnitRate = parseFloat(
        {
          "cubic metre": cubicMeterRate,
          gallon: cubicMeterRate * 264.172,
          bushel: cubicMeterRate * 28.3776,
          "acre foot": cubicMeterRate / 1233.0,
          "acre inch": cubicMeterRate / 102.79,
          megaliter: cubicMeterRate / 1000,
        }[toUnit.toLowerCase()],
      );

      finalValue = value * newUnitRate;
    } else if (type.toLowerCase() == "weight/area") {
      let originalParts = fromUnit.split("/");
      let originalWeightUnits = originalParts[0].toLowerCase();
      let originalAreaUnits = originalParts[1].toLowerCase();

      let newParts = toUnit.split("/");
      let newWeightUnits = newParts[0].toLowerCase();
      let newAreaUnits = newParts[1].toLowerCase();

      let kgRate = parseFloat(
        {
          kg: 1.0,
          lb: 1.0 / 2.205,
          tonne: 1000.0,
          "short ton": 907.0,
        }[originalWeightUnits],
      );

      let newKGRate = parseFloat(
        {
          kg: kgRate,
          lb: kgRate * 2.205,
          tonne: kgRate / 1000.0,
          "short ton": kgRate / 907.0,
        }[newWeightUnits],
      );

      let dunamRate = parseFloat(
        {
          dunam: 1.0,
          hectare: 10.0,
          acre: 4.047,
        }[originalAreaUnits],
      );

      let newDunamRate = parseFloat(
        {
          dunam: dunamRate,
          hectare: dunamRate / 10.0,
          acre: dunamRate / 4.047,
        }[newAreaUnits],
      );

      finalValue = (parseFloat(value) * newKGRate) / newDunamRate;
    }

    return finalValue.toFixed(2);
  }

  return "";
};

export var calculateVolume = (
  area,
  areaUnits,
  height,
  heightUnits,
  desiredVolumeUnits,
) => {
  let areaInDunams = convert(area, areaUnits, "dunam", "area");
  let lengthInMM = convert(height, heightUnits, "mm", "length");

  let volumeInCubicMeter = areaInDunams * lengthInMM;

  let volumeInDesiredUnits = convert(
    volumeInCubicMeter,
    "cubic metre",
    desiredVolumeUnits,
    "volume",
  );

  return volumeInDesiredUnits;
};

export const irrigationAmountToDuration = (irrAmount, irrigation_rate) => {
  return irrAmount / irrigation_rate;
};

export const irrigationDurationToAmount = (irrDuration, irrigation_rate) => {
  return irrDuration * irrigation_rate;
};

export const nitroUnitsToFertUnits = (nitroUnits) => {
  return nitroUnits.replace("N/", "/");
};

export const fertUnitsToNitroUnits = (nitroUnits) => {
  return !nitroUnits.includes("N/")
    ? nitroUnits.replace("/", "N/")
    : nitroUnits;
};
